import React from 'react';

import cls from './HomePage.module.scss';

const HomePage = () => {
	return (
		<div className={cls.homePage}>
			<div className={cls.title}>
				<span>D</span>
				<span>o</span>
				<span>o</span>
				<span>d</span>
				<span>l</span>
				<span>e</span>
			</div>
			<input className={cls.input} disabled />
			<div className={cls.buttons}>
				<button className={cls.searchButton} disabled>
					Search
				</button>
				<button className={cls.luckyButton} disabled>
					I'm not Lucky
				</button>
			</div>
		</div>
	);
};

export const tabOptions = {
	title: 'Home',
	url: 'doodle.com',
	content: <HomePage />
};

export default HomePage;
